* {
  margin: 0;
  padding: 0;
  background-color: #000000;
  color: #fff;
}

.app {
  position: relative;
  height: 650px;
  width: 350px;
  background-color: #fff;
  border-radius: 25px;
  transform: translate(125%, 5%);
}

.container {
  position: absolute;
  height: 550px;
  width: 330px;
  background-color: #000000;
  margin-left: 10px;
  margin-top: 48px;
  border-radius: 10px;
}

.app_top {
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 15px;
  margin-left: 10px;
}

.app_top .app_top_flat {
  width: 50px;
  height: 5px;
  background-color: #000000;
  margin-right: 20px;
  border-radius: 3px;
}

.app_top .app_top_round {
  height: 15px;
  width: 15px;
  background-color: #000000;
  border-radius: 10px;
  margin-right: 20px;
}

@media(max-width: 768px) {
  .app {
    height: 600px;
    width: 300px;
    overflow-x: hidden;
    transform: translate(5%, 5px);
    margin-left: 5%;
  }
  .container {
    height: 500px;
    width: 280px;
  }
}