.emergency_info {
    width: 100%;
}

.emergency_info button {
    margin: 10px;
    margin-left: 5%;
    width: 90%;
    padding: 10px;
    outline: none;
    border: none;
    background-color: #222;
    border-radius: 5px;
    font-size: 15px;
    text-transform: uppercase;
}

.emergency_input {
    display: flex;
    flex-direction: row;
    margin: 20px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
}

.emergency_input input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 20px;
}

.numberpad1, .numberpad0 {
    margin-left: 17%;
}
.numberpad1 button {
    align-items: center;
    margin: 10px;
    padding: 5px;
    width: 20%;
    outline: none;
    border: none;
}

.numberpad1 button h2 {
    font-size: 35px;
    font-weight: 500;
}

.numberpad1 button p {
    text-transform: uppercase;
    font-size: 11px;
}

.numberpad0 button {
    align-items: center;
    margin: 10px;
    padding: 5px;
    width: 20%;
    outline: none;
    border: none;
}

.numberpad0 button h2 {
    font-size: 30px;
}
.dail_button {
    text-align: center;
    scale: 1.5;
    width: 50%;
    margin-left: 25%;
    margin-top: 10%;
}