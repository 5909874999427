.lockedscreen-lock {
    width: 100%;
    align-items: center;
}
.lockedscreen-lock p {
    text-align: center;
    margin-top: 10%;
}

.lockedscreen-data {
    width: 100%;
    align-items: center;
    cursor: pointer;
}

.lockedscreen-data:hover {
    animation: animate 1s linear alternate;
}

@keyframes animate {
    0% {
        transform: translateY(-25px);
    }
    25% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.lockedscreen-data h2 {
    text-align: center;
    font-size: 40px;
    margin-top: 10%;
}

.lockedscreen-data h4 {
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
}

.lockedscreen-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    margin-top: 32%;
}

.lockedscreen-bottom a h4 {
    text-transform: uppercase;
}

.lockscreen-unlock {
    width: 90%;
}
.lockscreen-unlock a h4 {
    font-size: 15px;
    text-decoration: none;
    text-align: center;
    margin-top: 40%;
    margin-left: 14%;
    text-transform: uppercase;
    background-color: #222;
    padding: 10px;
    border-radius: 999px;
}
