.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #000000;
}

.container_top_right {
    background-color: #000000;
    display: flex;
    flex-direction: row;
}

.container_top_right_icons {
    background-color: #000000;
    display: flex;
    flex-direction: row;
}

.container_top_right_icons p {
    background-color: #000000;
    scale: 0.7;
}

.charge {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.charge p {
    background-color: #000000;
    scale: 0.7;
}